import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const changeNav = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const data = entry.target.dataset.id
          const currentLink = document.querySelector(`.nav-link[data-id="${data}"]`)
          document.querySelector('.nav-link.active').classList.remove('active')
          currentLink.classList.add('active')
        }
      })
      this.scrollFilterBar()
    }
    const options = {
      threshold: 0.30
    }
    const observer = new IntersectionObserver(changeNav, options)
    const sections = document.querySelectorAll('.scroll-spy')
    sections.forEach((i) => {
      observer.observe(i)
    })
  }

  scrollToCategory(event) {
    event.preventDefault()
    // debugger

    const data = event.currentTarget.getAttribute('data-id')
    const target = document.getElementById(`categories-${data}`)

    event.currentTarget.classList.add('active')
    const items = document.querySelectorAll('.nav-link')
    items.forEach(item => {
      if (item !== event.currentTarget) {
        item.classList.remove('active')
      }
    })

    if (target) {
      // const filterHeight = document.querySelector('.filter-bar').getBoundingClientRect().height
      // const elementHeight = target.getBoundingClientRect().top
      // let headerHeight = 0
      // if (window.innerWidth <= 992) {
      //   headerHeight = document.querySelector('.main-menu').getBoundingClientRect().height
      // }
      // document.querySelector('main').scrollBy({
      //   top: elementHeight - document.body.getBoundingClientRect().top - filterHeight - headerHeight,
      //   behavior: "smooth",
      // })
      target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    this.scrollFilterBar()
  }

  scrollFilterBar() {
    const activeLink = document.querySelector('.nav-link.active')
    const filterBar = document.querySelector('.filters')

    const activeLinkRect = activeLink.getBoundingClientRect()
    const filterBarRect = filterBar.getBoundingClientRect()
    const position = activeLinkRect.left - filterBarRect.left - (filterBarRect.width / 2) + (activeLinkRect.width / 2)

    filterBar.scrollBy({
      left: position,
      behavior: 'smooth',
    })
  }
}
