import { OrgChart } from "d3-org-chart";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];
  static values = { chartData: String, i18nShowNext: String };

  connect() {
    setTimeout(() => {
      this.renderChart();
    }, 100);
  }

  renderChart() {
    try {
      const orgChartData = JSON.parse(this.chartDataValue);
      let isMobile = window.matchMedia("only screen and (max-width: 480px)").matches;

      const chart = new OrgChart()
        .compact(isMobile)
        .pagingStep((d) => 5)
        .minPagingVisibleNodes((d) => 20)
        .pagingButton((d, i, arr, state) => {
          const step = state.pagingStep(d.parent);
          const currentIndex = d.parent.data._pagingStep;
          const diff = d.parent.data._directSubordinatesPaging - currentIndex;
          const min = Math.min(diff, step);
          return `
            <div style="margin-top:50px;">
              <div style="display:flex;width:170px;border-radius:20px;padding:5px 15px; padding-bottom:4px;background-color:#E5E9F2">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.59 7.41L10.18 12L5.59 16.59L7 18L13 12L7 6L5.59 7.41ZM16 6H18V18H16V6Z" fill="#716E7B" stroke="#716E7B"/>
                  </svg>
                </div>
                <div style="line-height:2">${this.i18nShowNextValue}</div>
              </div>
            </div>
          `;
        })
        .nodeHeight((d) => d.id === 'root' ? 50 : 110)
        .nodeWidth(() => 222)
        .childrenMargin(() => 50)
        .compactMarginBetween(() => 35)
        .compactMarginPair(() => 30)
        .neighbourMargin(() => 20)
        .nodeContent((d) => {
          const color = d.data.color || '#FFFFFF';
          const imageDiffVert = 25 + 2;
          const totalHeight = d._children || d.children ? d.height : d.height - imageDiffVert;

          let teamLeadAvatars = '';
          let teamLeadNames = '';
          let teamLeadId = '';

          const teamLead = d.data.team_lead;
          if (teamLead) {
            teamLeadId = teamLead.id;
            teamLeadAvatars = `<img src="${teamLead.image}" style="border-radius: 100px; width: 40px; height: 40px; margin-right: 5px;"/>`;
            teamLeadNames = `<div style="margin-right: 10px; display: flex; align-items: center;">${teamLead.name}</div>`;
          }

          if (d.id.startsWith("team_") || d.id === "root") {
            let employeeCountHTML = '';
            if (d.data.parentId !== null && d.depth !== 0) {
              employeeCountHTML = `<div style="color:#716E7B;font-size:10px;">${d.data.employee_count}</div>`;
            }

            let teamLeadContent = '';
            if (teamLeadAvatars !== '' && teamLeadNames !== '') {
              teamLeadContent = `
                <a href="#" data-action="click->org-chart#handleCardClick" data-id="${teamLeadId}" style="text-decoration: none; color: inherit;">
                  <div style="padding-top:3px; display: flex; align-items: center;">
                    ${teamLeadAvatars}
                    ${teamLeadNames}
                  </div>
                </a>`;
            }

            return `
              <div style='border-bottom: 6px solid ${color}; border-radius: 0 0 10px 10px;'>
                <div style='width:${d.width}px;height:${totalHeight}px;padding-top:${imageDiffVert - 2}px;padding-bottom:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px;background-color:#FFFFFF;border-radius:10px;border:${d.data._highlighted || d.data._upToTheRootHighlighted ? '5px solid #E27396' : '1px solid #E4E2E9'}; display: flex; align-items: center; justify-content: ${d.depth === 0 ? 'center' : 'left'}; flex-direction: column;'>
                  <div style="display:flex;align-items:center;margin-right:5px;">
                    <div style="font-size:15px;color:#08011E;margin-left:20px;margin-right:5px; text-align: center;">${d.data.name}</div>
                    <div style="margin-left:3px; margin-right:3px;">
                      <svg class="fa-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style="width: 10px; height: 10px;">
                        <path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48zM274.7 304H173.3c-95.73 0-173.3 77.6-173.3 173.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM48.71 464C55.38 401.1 108.7 352 173.3 352H274.7c64.61 0 117.1 49.13 124.6 112H48.71zM479.1 320h-73.85C451.2 357.7 480 414.1 480 477.3C480 490.1 476.2 501.9 470 512h138C625.7 512 640 497.6 640 479.1C640 391.6 568.4 320 479.1 320zM432 256C493.9 256 544 205.9 544 144S493.9 32 432 32c-25.11 0-48.04 8.555-66.72 22.51C376.8 76.63 384 101.4 384 128c0 35.52-11.93 68.14-31.59 94.71C372.7 243.2 400.8 256 432 256z"/>
                      </svg>
                    </div>
                    ${employeeCountHTML}
                  </div>
                  ${teamLeadContent}
                </div>
              </div>`;
          } else {
            let imageHTML = '';
            if (d.data.parentId !== null) {
              imageHTML = `<div><img src="${d.data.image}" style="border-radius: 100px; width: 40px; height: 40px;"/></div>`;
            }
            return `
              <div style='border-bottom: 6px solid ${color}; border-radius: 0 0 10px 10px; cursor: pointer;' data-action="click->org-chart#handleCardClick" data-id="${d.id}">
                <div style='width:${d.width}px;height:${totalHeight}px;padding-top:${imageDiffVert - 2}px;padding-bottom:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px;display: flex; align-items: center;background-color:#FFFFFF; text-align: center;'>
                  ${imageHTML}
                  <div style="font-size:15px;color:#08011E;margin-left:10px;">
                    ${d.data.name}
                  </div>
                </div>
              </div>`;
          }
        });

      chart.container(this.containerTarget);
      chart.data(orgChartData);
      chart.render();

    } catch (error) {
      console.error("Erreur lors de l'initialisation du graphique :", error);
    }
  }

  handleCardClick(event) {
    const employeeId = event.currentTarget.dataset.id.replace("employee_", "");
    const profileUrl = `/profiles/${employeeId}`;
    window.location.href = profileUrl;
  }
}
